import React, {useState, useContext, useEffect} from 'react'
import {API} from 'aws-amplify'
import {
    Box,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Typography,
    TextField,
    Button,
    IconButton,
    Checkbox,
    FormControlLabel,
    FormLabel,
    FormGroup,
    FormControl,
} from '@mui/material'

import {GlobalStateContext} from '../../context/GlobalContextProvider'
import Layout from '../../layouts/index'
import RoleAccessChip from '../../components/attendance/roleAccessChip'
import TagAccessAdd from '../../components/attendance/tagAccessAdd'
import DeleteItem from '../../components/attendance/deleteItem'
import AccessCheck from '../../components/attendance/accessCheck'
import Hider from '../../components/utils/Hider'
import Cfptb from "../../components/cfp/cfptb";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {CfpSearch} from "../../components/cfp/cfpSearch";
import {gUtils} from "../../components/utils/gutils";

export default function Role({location}) {
    const [tags, setTags] = useState([])
    const [role, setRole] = useState('')
    const [roleCheck, setroleCheck] = useState(true)
    const initRole = [{tag: '', companyID: '', access: ['1', '2']}]
    const [companyRoles, setCompanyRoles] = useState(initRole)
    const [temcompanyRoles, setTemCompanyRoles] = useState([])
    const [open, setOpen] = useState(false)
    const appAccessTags = gUtils.allTags
    const [tagWarining, setTagWarining] = useState([])
    const {gStripe} = useContext(GlobalStateContext)

    const handleTagsChange = (e) => {
        const index = tags.indexOf(e.target.value)
        if (index === -1) {
            setTags([...tags, e.target.value])
        } else {
            setTags(tags.filter((tag) => tag !== e.target.value))
        }
    }
    const getRole = (companyID) => {
        API.get('Attendance', `/tag`, {
            queryStringParameters: {
                companyID,
            },
        })
            .then((res) => {
                console.log("获取企业角色全选",res)

                 let  result=res.map(item=>{
                     item.isShowAdd=true
                     if (item.tag==='オーナー'){
                         item.access=gUtils.allTags
                         item.isShowAdd=false
                     }else  if (item.tag==='ゲスト'){
                         item.access=['権限なし']
                         item.isShowAdd=false
                     }else  if (item.tag==='マネージャー'){
                         item.access=gUtils.allTags
                         item.isShowAdd=false
                     }
                     return item
                 })


                setCompanyRoles(result)
            })
            .catch((err) => console.log(err))
    }
    const changeSingleAccess = (tagSetting, companyID) => {
        API.post('Attendance', `/tag`, {
            body: {...tagSetting, companyID},
        })
            .then(() => {
                getRole(gStripe.customer.id)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        //確認global內的stripe customer從初始值變成fetch回來的值，再執行。不然會呈現null
        if (gStripe.customer.id) {
            getRole(gStripe.customer.id)
        }
    }, [gStripe.customer.id])

    return (<Layout location={location}>
            <AccessCheck access='ユーザー'>

                <CfpSearch
                    callSearch={(e) => {

                        console.log("当前companyRoles", companyRoles, e)
                        if (temcompanyRoles.length === 0) {
                            setTemCompanyRoles(companyRoles)
                        }
                        if (e) {

                            let nList = temcompanyRoles.filter(item => {
                                if (item.tag.includes(e)) {
                                    return true
                                }
                                if (item.access.some(item => item.includes(e))) {
                                    return true
                                }
                                return false

                            })
                            setCompanyRoles(nList)
                        } else {


                            setCompanyRoles(temcompanyRoles)
                            setTemCompanyRoles([])

                        }
                    }}
                />
                <Hider show={open}>
                    <Card sx={{mb: '15px'}}>
                        <CardHeader
                            title={<Box sx={{display: 'flex', alignItems: 'be', justifyContent: 'space-between'}}>

                                <Typography variant='h2' sx={{m: '0px 10px 0px 0px'}}>
                                    新規ロールを追加
                                </Typography>

                                <IconButton onClick={() => {
                                    setOpen(false)
                                }}>

                                    <KeyboardArrowDownIcon/>
                                </IconButton>

                            </Box>


                            }
                        />
                        <CardContent
                            sx={{
                                paddingBottom: 'unset',
                            }}
                        >
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item xs={6}>
                                    <TextField
                                        size='small'
                                        label='ロールの名称'
                                        variant='outlined'
                                        fullWidth
                                        required
                                        error={!roleCheck}
                                        helperText={roleCheck ? '' : 'デフォルトの権限と同じ名前は使用できません'}
                                        onChange={(e) => {
                                            setRole(e.target.value)
                                            setroleCheck(e.target.value === 'オーナー' || e.target.value === 'マネージャー' || e.target.value === 'ゲスト' ? false : true)
                                        }}
                                        value={role}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl sx={{display: 'center', alignItems: 'center'}}>
                                        <FormLabel sx={{mr: '15px'}}>閲覧可能ページ</FormLabel>
                                        <FormGroup
                                            sx={{
                                                width: '100%', display: 'flex', flexDirection: 'row',
                                            }}
                                        >
                                            {appAccessTags.map((tag) => {
                                                return (<FormControlLabel
                                                        control={<Checkbox
                                                            onChange={(e) => {
                                                                handleTagsChange(e)
                                                            }}
                                                            checked={tags.includes(tag)}
                                                        />}
                                                        label={tag}
                                                        value={tag}
                                                    />)
                                            })}
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant='outlined'
                                        size='small'
                                        disabled={role === '' || tags.length === 0 || !roleCheck}
                                        onClick={() => {
                                            API.post('Attendance', `/tag`, {
                                                body: {
                                                    access: tags, companyID: gStripe.customer.id, tag: role,
                                                },
                                            })
                                                .then(() => {
                                                    setTags([])
                                                    setRole('')
                                                    getRole(gStripe.customer.id)
                                                })
                                                .catch((err) => console.log(err))
                                        }}
                                    >
                                        登録
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Hider>

                <Cfptb
                    callAdd={() => {
                        setOpen(true)
                    }}
                    selectableRows={'none'}
                    isAdd={!open}
                    data={companyRoles}
                    isCsv={false}
                    isBind={false}
                    isDel={false}
                    text={"ロール一覧"}
                    columns={[{
                        name: 'tag', label: 'ロール', options: {
                            filter: true, filterType: 'dropdown', sort: false,
                        },
                    }, {
                        name: 'access', label: '閲覧可能ページ', options: {
                            filter: false, sort: false, customBodyRenderLite: (dataIndex) => {
                                const tagSetting = companyRoles[dataIndex]
                                let isShowAdd=tagSetting.isShowAdd

                                console.log("isShowAddisShowAdd",isShowAdd)

                                return (<>
                                        {tagSetting.access.map((item, index) => {
                                            let isLastRoleAccessChip = tagSetting.access.length === 1
                                            return (
                                                <RoleAccessChip
                                                    isShowAdd={isShowAdd && !isLastRoleAccessChip}
                                                    id={dataIndex}
                                                    key={index}
                                                    handleCheck={() => {
                                                        //1.前端陣列刪除所點擊的項目
                                                        let tmp = [...companyRoles]
                                                        let deleteFromAccess = tagSetting.access.filter((i) => i !== item)
                                                        tmp[dataIndex].access = deleteFromAccess
                                                        setCompanyRoles(tmp)
                                                        //2.後端覆蓋刪除完的資料
                                                        changeSingleAccess({...tagSetting}, gStripe.customer.id)
                                                    }}
                                                    label={item}
                                                    rowChipLength={tagSetting.access.length}
                                                    tag={tagSetting.tag}
                                                />)
                                        })}

                                    {isShowAdd&&   <TagAccessAdd
                                        tag={tagSetting}
                                        appAccessTags={appAccessTags}
                                        changeSingleAccess={changeSingleAccess}
                                    />}

                                    </>)
                            },
                        },
                    }, {
                        name: '削除', options: {
                            filter: false, sort: false, empty: true, customBodyRenderLite: (dataIndex) => {
                                const tagSetting = companyRoles[dataIndex]

                                let isShowAdd=tagSetting.isShowAdd
                                return (<>
                                        { isShowAdd && (
                                            <Hider
                                                show={tagWarining.indexOf(companyRoles[dataIndex].tag) === -1}
                                            >
                                                <DeleteItem
                                                    handleCheck={() => {

                                                        console.log("准备删除", tagSetting)

                                                        let id = gStripe.customer.id
                                                        API.del('Attendance', `/tag`, {
                                                            body: tagSetting,
                                                        })
                                                            .then((res) => {

                                                                getRole(id)
                                                            })
                                                            .catch((e) => {
                                                                console.log(e, 'e')
                                                                  setTagWarining([
                                                                        ...tagWarining,
                                                                        companyRoles[dataIndex].tag,
                                                                    ])
                                                                console.log('[tag]  錯誤', e)
                                                            })
                                                    }}
                                                />
                                                <Typography
                                                    sx={{color: 'error.main', fontSize: '14px'}}
                                                >
                                                    このロールは現在使用されていますので削除できません。
                                                </Typography>
                                            </Hider>)}
                                    </>)
                            },
                        },
                    }

                    ]}


                />

            </AccessCheck>
        </Layout>)
}
