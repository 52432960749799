    import {createTheme, ThemeProvider} from "@mui/material/styles"
    import MUIDataTable from "mui-datatables"
    import {
        Box,
        Stack,
        IconButton,
        Menu,
        MenuItem,
        Tooltip,
        Typography,
    } from "@mui/material"
    import AddCircleIcon from "@mui/icons-material/AddCircle"
    import LinkIcon from "@mui/icons-material/Link"
    import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
    import Divider from "@mui/material/Divider"
    import DeleteIcon from "@mui/icons-material/Delete"
    import {navigate} from "gatsby"
    import React, {useEffect, useState} from "react"
    import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
    import {gUtils} from "../utils/gutils"
    import KeyIcon from "@mui/icons-material/Key"
    import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload'

    export default function Cfptb({
                                      data,
                                      text,
                                      columns,
                                      path,
                                      callAdd,
                                      callBind,
                                      callDelData,
                                      isSelect=false,
                                      selectableRows = 'multiple',
                                      isAdd = true,
                                      isKey = false,
                                      isDel = true,
                                      isCsv = true,
                                      isBind = true,
                                      isLine = true,
                                      isBack = false,
                                      isHideToolBar = false,
                                      adjustToolBar = false,
                                      isPagination = true,
                                      isSmallHeight = false,
                                      btnCallKey,
                                      isRowFilter = undefined,
                                      isShowFooter=true,
                                      ref=null,
                                      itemsSelect,
                                      refreshTb=0x23,
                                      callSelects,

                                  }) {
        const [anchorEl, setAnchorEl] = useState(null)
        const [selectedRows, setSelectedRows] = useState([])
        const [key, setKey] = useState(undefined)
        const [rowsList, setRowsList] = useState([])
        const handleMenuOpen = (event) => {
            setAnchorEl(event.currentTarget)
        }
        useEffect(() => {
            if (rowsList.length===0){
                setSelectedRows([])
            }else {

                setSelectedRows(rowsList.map((index) => {
                    return data[index]
                }))
            }

        }, [rowsList,data]);
        useEffect(()=>{

            if (callSelects)callSelects(selectedRows)



        },[selectedRows])
        useEffect(()=>{

            console.log("初始化数据",refreshTb)
            setAnchorEl(null)
            setRowsList([])
            setKey(refreshTb)



        },[refreshTb])




        const handleDeleteClick = async (event) => {
            setAnchorEl(event.currentTarget)
            if (selectedRows.length > 0) {
                try {
                    console.log("当前选择selectedRows", selectedRows)

                    if (callDelData) callDelData(selectedRows)
                    setRowsList([])
                } catch (error) {
                    // エラーハンドリング
                    console.error(error)
                }
            }
        }

        const handleDeleteClose = () => {
            setAnchorEl(null)
        }




        const getMuiTheme = (len) =>
            createTheme({
                palette: {
                    primary: {
                        main: "#28aeb1",
                    },
                    info: {
                        light: "#cccccc",
                        main: "#666666",
                    },
                    error: {
                        light: "#db807c",
                        main: "#CC4A44",
                    },
                },
                typography: {
                    fontFamily: "'Noto Sans JP', sans-serif",
                    h2: {
                        fontSize: "22px",
                        fontWeight: "bold",
                    },
                    h4: {
                        fontSize: "16px",
                    },
                    h5: {
                        fontSize: "14px",
                    },
                    button: {
                        textTransform: "none",
                    },
                },
                components: {
                    MuiTypography: {
                        styleOverrides: {
                            root: {
                                letterSpacing: "0.02rem",
                            },
                        },
                    },
                    MUIDataTableToolbar: {
                        styleOverrides: {
                            root: {
                                height: isHideToolBar ? '0px' : 'auto',
                                display: isHideToolBar ? 'none' : 'block',
                                marginBottom: adjustToolBar ? '-30px': "0px"
                            },
                        },
                    },
                    MuiTableCell: {
                        /* styleOverrides: { root: { "&:hover": { cursor: "pointer" } } },*/

                        styleOverrides: {
                            root: {
                                border: "none",
                                "&:first-of-type": {
                                    borderTopLeftRadius: "6px",
                                    borderBottomLeftRadius: "6px",
                                },
                                "&:last-of-type": {
                                    borderTopRightRadius: "6px !important",
                                    borderBottomRightRadius: "6px !important",
                                },

                            },
                        },
                    },
                    MuiTableRow: {
                        styleOverrides: {
                            root: {
                                "&:nth-of-type(odd)": {
                                    backgroundColor: "#f8f8f8",
                                },
                                "&:hover": {
                                    cursor: "pointer",
                                },
                            },
                            footer: {
                                backgroundColor: 'white !important', // TableFooter的背景顏色
                            },
                        },
                    },
                    MUIDataTableBodyRow: {
                        styleOverrides: {
                            root: {
                                height: isSmallHeight ? "20px" : '70px' ,// 设置你想要的高度
                                border: "none"
                            },
                        },
                    },
                },
            })

        return (
            <Stack
                sx={{
                    marginRight: "20px",
                    marginLeft: "20px",
                    marginTop: adjustToolBar ? "20px" : "0px"
                }}>
                <ThemeProvider theme={getMuiTheme(data.length)}>
                    <MUIDataTable
                        ref={ref}
                        key={key?key:0x888}
                        data={data}
                        title={


                            <Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop:text? "20px":'-10px',
                                        marginLeft:'-20px',
                                        flexDirection: "row",
                                    }}
                                >
                                    {isBack && (
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                window.history.back()
                                            }}
                                        >
                                            <KeyboardArrowLeftIcon />


                                        </IconButton>
                                    )}

                                    <Typography variant="h2" sx={{fontWeight: "bold",marginLeft: "9px"}}>
                                        {text}
                                    </Typography>

                                    {isAdd && (
                                        <IconButton
                                            onClick={() => {
                                                if (callAdd) callAdd()
                                            }}
                                        >
                                            <AddCircleIcon style={{color: "#28AEB1"}}/>
                                        </IconButton>
                                    )}
                                </Box>


                                <Box
                                    sx={{
                                        display: 'flex',
                                        marginLeft: "2px",
                                        flexDirection: "row",
                                        alignItems: "center", // 添加这个属性以垂直居中元素
                                    }}
                                >
                                    {isBind && (
                                        <Tooltip title="他の認証機器と連携">
                                            <IconButton
                                                onClick={() => {
                                                    if (callBind) callBind()
                                                }}
                                            >
                                                <LinkIcon
                                                    fontSize="small"
                                                    sx={{
                                                        color: "rgba(0, 0, 0, 0.54);",
                                                    }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {isKey && (
                                        <Tooltip title="合鍵発行">
                                            <IconButton
                                                onClick={(event) => {
                                                    event.stopPropagation()
                                                    if (btnCallKey) btnCallKey(selectedRows)
                                                }}
                                                onMouseDown={(event) => event.stopPropagation()}
                                                onMouseUp={(event) => event.stopPropagation()}
                                            >
                                                <KeyIcon
                                                    fontSize="small"
                                                    sx={{
                                                        color: "rgba(0, 0, 0, 0.54);",
                                                    }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {isCsv && (
                                        <Tooltip title="CSVダウンロード">
                                            <IconButton
                                                onClick={() => {
                                                    console.log("准备下载22222")

                                                    gUtils.csvUtils.downloadLists(data)
                                                }}
                                            >
                                                <CloudDownloadIcon
                                                    fontSize="small"
                                                    sx={{
                                                        color: "rgba(0, 0, 0, 0.54);",
                                                    }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {isCsv && (
                                        <Tooltip title="Excelダウンロード">
                                            <IconButton
                                                onClick={() => {
                                                    console.log("准备下载22222")

                                                    gUtils.csvUtils.downloadLists(data,false)
                                                }}
                                            >
                                                <SimCardDownloadIcon
                                                    fontSize="small"
                                                    sx={{
                                                        color: "rgba(0, 0, 0, 0.54);",
                                                    }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {isLine && (
                                        <Divider orientation="vertical" variant="middle" flexItem/>
                                    )}
                                    {isDel && (
                                        <Tooltip title="削除">
                                            <IconButton
                                                onClick={(event) => {
                                                    event.stopPropagation()
                                                    handleMenuOpen(event)
                                                }}
                                                onMouseDown={(event) => event.stopPropagation()}
                                                onMouseUp={(event) => event.stopPropagation()}
                                            >
                                                <DeleteIcon
                                                    fontSize="small"
                                                    sx={{
                                                        color: "rgba(0, 0, 0, 0.54);",
                                                    }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                    <Menu
                                        elevation={1}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center",
                                        }}
                                        MenuListProps={{disablePadding: true}}
                                        id="customized-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleDeleteClose}
                                    >
                                        <MenuItem
                                            disabled={selectedRows.length === 0}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                handleDeleteClick(e)
                                                setAnchorEl(null)
                                            }}
                                        >
                                            削除
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            </Box>
                        }
                        columns={columns}
                        options={{
                            customFooter: isShowFooter ? undefined : () => null, // 根据状态决定是否渲染底部部分
                            elevation: 0,
                            print: false,
                            download: false,
                            hover: false,
                            filter: false,
                            selectableRows: selectableRows,
                            pagination: isPagination,
                           // selectableRowsHeader:isRowSelect===undefined?true:false,
                            search: false,
                            viewColumns: false,
                            filterType: "checkbox",
                            rowsSelected:rowsList,
                            rowsPerPage: isShowFooter?50:10000, // 设置默认每页显示50行
                            rowsPerPageOptions: [10, 25, 50, 100], // 提供给用户的每页行数选项

                            selectableRowsHeader: () => {
                                console.log("Hey lunch")
                            },
                            selectToolbarPlacement: "none",
                            customToolbarSelect: () => {
                            },
                            setTableProps: () => {
                                return {fullWidth: true, size: "small"}
                            },
                            onRowSelectionChange: (
                                currentSelectedRows,
                                allSelectedRows,
                                rowsSelected
                            ) => {




                              //  console.log("当前选择3", rowsSelected,isRowFilter,data)

                             /*   const allSelectedRowsData = rowsSelected.map((index) => {
                                    return data[index]
                                })*/
                                if (isRowFilter) {

                                    let  rsd=rowsSelected.filter(item=>data[item].tag[0]!=='オーナー')

                                    setRowsList(rsd)
                                }else {
                                    setRowsList(rowsSelected)


                                }



                                //setSelectedRows(allSelectedRowsData)
/*
                                if (isRowFilter) {
                                    setKey(prevState => prevState+1)




                                }*/
                            },
                            onRowClick: (rowData, rowMeta) => {

                                if (path) {
                                    navigate(path, {
                                        state: {
                                            data: data[rowMeta.dataIndex],
                                            index: rowMeta.dataIndex,
                                        },
                                    })
                                }
                            },

                            textLabels: {
                              body: {
                                noMatch: '検索結果は０件です',
                              },
                              pagination: {
                                next: 'Next Page',
                                previous: 'Previous Page',
                                rowsPerPage: '表示数',
                                displayRows: '/',
                              },
                            }


                        }}
                    />
                </ThemeProvider>
            </Stack>

        )
    }
